<template>
   <v-expand-transition>
      <div v-show="typeof activity.id === 'string' || activity.id >= 0" style="background-color: #e3f2fd80;">
         <v-card-title class="text-h5 grey--text text--darken-2 mb-0" style="position: relative;">
            <div class="v-tabs-slider-wrapper white--text" style="height: 6px; bottom: 0; width: 48px;">
               <div class="v-tabs-slider" />
            </div>
            Approval Request
         </v-card-title>
         <v-card-text class="pb-9">
            <v-form ref="form" @submit.prevent="updateRecord">
               <v-row>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Name:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.name" outlined hide-details dense required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Phone:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.phone" outlined hide-details dense required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Email:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.email" outlined hide-details dense required :rules="[v => !v || /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Face:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field :prefix="form.Face ? '....' : ''" :value="form.Face.substr(form.Face.length - 32)" outlined hide-details required :rules="[v => !!v]" dense readonly>
                        <template v-slot:append>
                           <v-btn large icon @click="camera.deviceId ? cameraStart('Face') : $refs.visitorFace.click()">
                              <v-icon>
                                 {{ camera.deviceId ? 'mdi-camera' : 'mdi-folder-open' }}
                              </v-icon>
                              <input ref="visitorFace" type="file" accept="image/*" alt="visitorFace" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                     <!-- USE CAMERA -->
                     <!--
                     <v-file-input outlined hide-details dense capture="user" accept="image/*" prepend-icon="" :clearable="false" required :rules="[v => !!v]"
                        @change="(file) => attachmentPreview(file, 'Face')"
                        >
                        <template v-slot:append>
                           <v-icon>
                              mdi-folder-open
                           </v-icon>
                        </template>
                     </v-file-input>
                     -->
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Tenant:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-autocomplete :items="filteredOrganizations" outlined hide-details dense required :rules="[v => !!v]"
                        item-text="name" item-value="identity" no-data-text="Typeahead and choose one"
                        prepend-inner-icon="mdi-database-search"
                        :return-object="true" :menu-props="{ offsetY: true, bottom: true }"
                        :search-input.sync="search.input"
                        @change="changeOrganization"
                        />
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Card:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field :prefix="form.Card ? '....' : ''" :value="form.Card.substr(form.Card.length - 32)" outlined hide-details required :rules="[v => !!v]" dense readonly>
                        <template v-slot:append>
                           <v-btn large icon @click="camera.deviceId ? cameraStart('Card') : $refs.visitorCard.click()">
                              <v-icon>
                                 {{ camera.deviceId ? 'mdi-camera' : 'mdi-folder-open' }}
                              </v-icon>
                              <input ref="visitorCard" type="file" accept="image/*" alt="visitorCard" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                     <!-- USE CAMERA -->
                     <!--
                     <v-file-input outlined hide-details dense capture="environment" accept="image/*" alt="activityCard" prepend-icon="" :clearable="false" required :rules="[v => !!v]"
                        @change="(file) => attachmentPreview(file, 'Card')"
                        >
                        <template v-slot:append>
                           <v-icon>
                              mdi-folder-open
                           </v-icon>
                        </template>
                     </v-file-input>
                     -->
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Floor:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-select v-model="form.floor" :items="filteredFloors" outlined hide-details dense required :rules="[v => !!v]"
                        item-text="name" item-value="identity" :disabled="!filteredFloors.length"
                        :return-object="false" :menu-props="{ offsetY: true, bottom: true }"
                        />
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Reason:
                     </p>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-combobox v-model="form.reason" :items="reasons" outlined hide-details dense required :rules="[v => !!v]"
                        item-text="name" item-value="name" :return-object="false"
                        :menu-props="{ offsetY: true }"
                        @input.native="form.reason=$event.srcElement.value"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="11" :class="{'d-flex justify-end': $vuetify.breakpoint.mdAndUp, 'd-flex justify-center': $vuetify.breakpoint.smAndDown}">
                     <v-btn large color="primary" depressed class="ml-4" type="submit">
                        <!-- @click="updateRecord" -->
                        Submit
                     </v-btn>
                     <v-btn large color="primary" depressed outlined class="ml-4" @click="activity.id = -1">
                        Cancel
                     </v-btn>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>
         <v-dialog v-model="dialog.show" :max-width="dialog.type === 'Card' ? 854 : 640" @click:outside="cameraEnded">
            <v-card dark class="pa-4">
               <v-sheet color="black" style="justify-content: center; display: flex; overflow: hidden; border-radius: 0;" height="474">
                  <video ref="video" :width="dialog.type === 'Card' ? 854 : 640" height="100%" />
               </v-sheet>
               <v-progress-circular v-if="!dialog.ready" width="4" :size="64" indeterminate color="primary" style="position: absolute; top: calc(50% - 32px); left: calc(50% - 32px);" />
               <v-card-actions style="position: absolute; bottom: 20px; right: 20px;">
                  <v-btn fab dark color="red darken-2" class="ml-4" @click="cameraEnded">
                     <v-icon large>
                        mdi-close-thick
                     </v-icon>
                  </v-btn>
                  <v-btn fab dark color="teal darken-2" class="ml-4" @click="cameraImage">
                     <v-icon large>
                        mdi-check-bold
                     </v-icon>
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-dialog>
      </div>
   </v-expand-transition>
</template>

<script>
import { blobImageToBase64 } from '@/utils/nirtara'
import { approval } from '@/utils/fields.js'
export default {
   name: 'NApprovalForm',

   props: {
      activity: { type: Object },
      operator: { type: Object },
      organizations: { type: Array },
      floors: { type: Array },
      reasons: { type: Array },
   },

   data: () => ({
      isDebugging: false,
      isUsed: false,
      form: Object.assign({}, approval),
      // fileface: null,
      // filecard: null,
      search: { input: null, organization: {}, organizations: [] },
      select: { floor: [], floors: [] },
      camera: {},
      dialog: { show: false, type: 'Face', ready: false },
   }),

   computed: {
      filteredOrganizations () {
         return (this.search.input && this.search.input.length > 0)
            ? this.organizations.filter(j => j.Receptionists.length)
            : []
      },
      filteredFloors () {
         const floors = (this.search.organization.Floors || []).map(j => j.identity)
         return this.floors.filter(j => floors.includes(j.identity))
      },
   },
   watch: {
      'activity.id' (id) {
         // console.log(`watch ( id = ${id} )`)
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, approval, id > 0 ? this.activity : { id: 0 })
         const { form } = this // , activity, search, select
         if (id === 0) {
            Object.assign(form, approval, { id: 0, activity: 'Register' }, this.isDebugging ? { name: '11', phone: '22', email: '33@33.33', reason: '44' } : null)
         }
      },
   },
   mounted () {
      this.devices().then(result => {
         this.camera = result.find(j => j.kind === 'videoinput') || {}
         // console.log(`mounted ( deviceId = ${this.camera.deviceId} )`)
      })
   },
   methods: {
      changeOrganization (item) {
         // console.log('changeOrganization (item)', item && JSON.parse(JSON.stringify(item)))
         this.search.organization = item
         this.select.floor = {}
         item && (this.select.floors = item.Floors.map(j => (this.floors.find(f => f.identity === j.identity) || { name: j.identity, identity: j.identity })))
      },
      /*
      attachmentPreview (file, type) {
         if (!file) return
         // console.log(`attachmentPreview (${type})`, file)
         blobImageToBase64(file)
         .then(base64 => {
            switch (type) {
               default: console.log(`${type}: ${base64.substr(0, 32)}....`)
                  break
               case 'Card': this.form.Card = base64
                  break
               case 'Face': this.form.Face = base64
                  break
            }
         })
      },
      */
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'visitorFace': this.form.Face = base64
                  break
               case 'visitorCard': this.form.Card = base64
                  break
            }
         })
      },

      async devices () {
         if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            throw (Error('UserMediaDetector getDevices failed: enumerateDevices is not supported'))
         }
         const mediaDevices = await navigator.mediaDevices.enumerateDevices()
         return mediaDevices.map(
            ({ deviceId, groupId, kind, label }) => ({ deviceId, groupId, kind, label }),
         )
      },
      cameraStart (type) {
         this.dialog.type = type
         this.dialog.show = true

         const constraints = { deviceId: { exact: this.camera.deviceId }, width: type === 'Card' ? 854 : 640, height: 480 }
         // console.log('cameraStart (constraints)', constraints)
         this.video && this.cameraEnded()
         navigator.mediaDevices.getUserMedia({ video: constraints, audio: false })
         .then(stream => {
            const video = this.$refs.video
            video.srcObject = stream
            video.srcObject.stop = function () {
               this.getVideoTracks().forEach(function (track) { // in case... :)
                  track.stop()
               })
            }
            video.play()
            this.video = video
            this.dialog.ready = true
         })
      },
      cameraEnded () {
         const video = this.video
         if (video && video.srcObject) {
            video.pause()
            video.srcObject.stop()
            video.srcObject = null
         }
         delete this.video
         this.dialog.show = false
         this.dialog.ready = false
      },
      cameraImage () {
         const video = this.video
         if (!video || !video.srcObject) return

         // console.log(`cameraImage ( ${video.videoWidth} x ${video.videoHeight} )`)
         const canvas = document.createElement('canvas')
         canvas.width = video.videoWidth
         canvas.height = video.videoHeight
         const context = canvas.getContext('2d')
         context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height)
         var base64 = canvas.toDataURL('image/png')
         // window.open(base64) // open the captured image in a new tab

         // console.log(`${this.dialog.type}: ${base64.substr(0, 32)}....`)
         this.form[this.dialog.type] = base64

         this.cameraEnded()
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         // const ipV4 = this.$store.state.ipV4 || (await (await fetch('https://api.ipify.org?format=json')).json()).ip
         const form = Object.assign({}, this.form, {
            organization: this.search.organization.identity,
            Activities: [{ Activity: [], name: 'Register', since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: this.operator.identity || 'None', ipV4: this.$store.state.ipV4 }],
            // valid: '', // this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            // until: this.$moment().format('YYYY-MM-DD 19:00:00'),
         })
         // console.log('updateRecord (form)', JSON.parse(JSON.stringify(form)))
         this.$emit('update', form)
      },
   },
}
</script>

<style lang="scss" scoped>
.theme--light.v-list {
   background: #e3f2fd;
}
::v-deep .v-list-item .v-list-item__title {
   font-size: 0.925rem;
   line-height: 1.1rem;
}
.v-btn:not(.v-btn--round).v-size--large {
   height: 44px;
   min-width: 150px;
}
.theme--light.v-btn.v-btn--icon {
   height: 38px;
   border-radius: 0 3px 3px 0;
   min-width: 80px;
   margin-right: -11px;
   margin-top: -7px;
   background-color: #01579b;
   color: #fefefe;
}
</style>
